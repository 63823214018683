import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useAppDispatch } from 'store/hooks';
import {
  useAddNewDealerMutation,
  useGetOneDealerQuery,
  useUpdateDealerMutation,
} from 'thunks/RTKQuery/dealer';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { toastSlice } from 'slices';
import { Spinner2 } from 'components/Spinner2';
import { isFetchBaseQueryError } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { dealerInitialState } from 'slices/dealer-details';
import { CONFIGURATION_TYPE } from 'constants/constants';
import { isValidJson } from 'utils/common';
import { FormikHelpers } from 'formik';
import i18n from 'translations';

import {
  IDealerItem,
  IDealerObject,
  IValidationDealer,
} from '../Models/dealers';
import { DealersDetailView } from './DealersDetailView';

interface IDealerDetailContainer {
  setErrorString: (err: string) => void;
  selectedDealer?: IDealerItem;
  rootDealer?: IDealerItem;
  setSelectedDealer: (dealer: IDealerItem) => void;
  appendListViewData: (data: IDealerItem) => void;
}

const isConfigurationJSON = (config: any) => {
  return config === CONFIGURATION_TYPE.FIXED_JSON;
};

const schema: yup.SchemaOf<IValidationDealer> = yup.object().shape({
  dealerId: yup.string().required(i18n.t('dealerManagement.requiredDealerId')),
  name: yup.string().required(i18n.t('dealerManagement.requiredName')),
  configurationType: yup
    .string()
    .required(i18n.t('dealerManagement.requiredConfigType'))
    .nullable(),
  fixedConfigJson: yup.string().when('configurationType', {
    is: (val: any) => isConfigurationJSON(val),
    then: yup
      .string()
      .test('is-json', i18n.t('dealerManagement.invalidJson'), isValidJson),
  }),
  parentDealerId: yup.string().optional(),
  email: yup
    .string()
    .nullable()
    .when('parentDealerId', {
      is: (val: string) => val === '' || val === undefined || val === null,
      then: yup
        .string()
        .email()
        .required(i18n.t('dealerManagement.requiredParentEmail'))
        .nullable(),
      otherwise: yup
        .string()
        .email(i18n.t('dealerManagement.emailNotValid'))
        .optional()
        .nullable(),
    }),
});

export const DealersDetailViewContainer = ({
  setErrorString,
  selectedDealer,
  setSelectedDealer,
  appendListViewData,
  rootDealer,
}: IDealerDetailContainer) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [dealerData, setDealerData] = useState<IDealerObject>({
    ...dealerInitialState.data,
    parentDealerId: rootDealer?.dealerId ?? '',
  });

  useEffect(() => {
    setDealerData({
      ...dealerData,
      parentDealerId: rootDealer?.dealerId ?? '',
    });
  }, [rootDealer]);

  const {
    data: detailData,
    isLoading: dealerDetailLoading,
    error: dealerDetailError,
    refetch: refetchDealerDetail,
  } = useGetOneDealerQuery(selectedDealer?.id ?? 0);

  useEffect(() => {
    if (!detailData) return;
    const fixedConfigJson =
      detailData && detailData.fixedConfigJson
        ? JSON.stringify(detailData.fixedConfigJson)
        : '';
    setDealerData({ ...detailData, fixedConfigJson });
  }, [detailData]);

  useEffect(() => {
    if (!selectedDealer) {
      setDealerData({
        ...dealerInitialState.data,
        parentDealerId: rootDealer?.dealerId ?? '',
      });
    }
    refetchDealerDetail();
  }, [selectedDealer]);

  const [
    addNewDealer,
    {
      isLoading: dealerSaveLoading,
      error: dealerSaveError,
      status: dealerSaveStatus,
    },
  ] = useAddNewDealerMutation();
  const [
    updateDealer,
    {
      isLoading: dealerUpdateLoading,
      error: dealerUpdateError,
      status: dealerUpdateStatus,
    },
  ] = useUpdateDealerMutation();

  useEffect(() => {
    if (dealerDetailError && 'message' in dealerDetailError) {
      setErrorString(`Error :${dealerDetailError.message}`);
    }
    if (dealerSaveError && isFetchBaseQueryError(dealerSaveError)) {
      const msg = dealerSaveError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
    if (dealerUpdateError && isFetchBaseQueryError(dealerUpdateError)) {
      const msg = dealerUpdateError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
  }, [dealerDetailError, dealerSaveError, dealerUpdateError]);

  const handleSubmit = async (
    values: IDealerObject,
    formik: FormikHelpers<IDealerObject>
  ) => {
    setErrorString('');
    let res: any;
    if (!selectedDealer?.id) {
      res = await addNewDealer(values);
    } else {
      res = await updateDealer(values);
    }
    if (!res?.error) {
      const dealerObj = res.data as IDealerItem;
      setSelectedDealer(dealerObj);
      appendListViewData(dealerObj);
    }

    formik.resetForm({ values: { ...values } });
  };

  useEffect(() => {
    if (dealerSaveStatus === QueryStatus.fulfilled && !dealerSaveError) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.save.title'),
          message: t('dealer.save.message'),
        })
      );
    }
  }, [dealerSaveStatus]);

  useEffect(() => {
    if (dealerUpdateStatus === QueryStatus.fulfilled && !dealerUpdateError) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.update.title'),
          message: t('dealer.update.message'),
        })
      );
    }
  }, [dealerUpdateStatus]);

  return (
    <>
      <Spinner2
        show={dealerDetailLoading || dealerUpdateLoading || dealerSaveLoading}
      />
      <DealersDetailView
        detailData={dealerData}
        validationSchema={schema}
        onSubmit={handleSubmit}
        rootDealer={rootDealer}
      />
    </>
  );
};

DealersDetailViewContainer.defaultProps = {
  selectedDealer: undefined,
  rootDealer: undefined,
};
