import { Switch } from 'components';
import { Spinner2 } from 'components/Spinner2';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetDealerConfigResultMutation } from 'thunks/RTKQuery/dealer';
import { HD_TICKET_FORMAT, HD_TICKET_FORMAT_STR } from 'utils/common';

export const DealerConfigResult: React.FC = () => {
  const { t } = useTranslation();
  const [ticketId, setTicketId] = React.useState<string>('');
  const [invalidTicketFormat, setInvalidTicketFormat] = React.useState<boolean>(
    false
  );
  const [wrapText, setWrapText] = React.useState<boolean>(false);
  const [isInitialCall, setIsInitialCall] = React.useState<boolean>(false);

  const [
    getConfigResult,
    { isError, error, isLoading, data },
  ] = useGetDealerConfigResultMutation();

  const handleSubmit = async () => {
    if (!ticketId) return;
    if (!HD_TICKET_FORMAT.test(ticketId)) {
      setInvalidTicketFormat(true);
      return;
    }
    await getConfigResult({
      ticketId,
      initialCall: isInitialCall,
    });
  };

  let errorMsg = isError
    ? (error as any)?.data?.message ?? t('unknownError')
    : '';

  if (typeof errorMsg === 'object') {
    errorMsg = JSON.stringify(errorMsg);
  }

  return (
    <Container>
      <Spinner2 show={isLoading} />
      <Row className="mb-30">
        <Col>
          <h3>{t('dealerConfig.test')}</h3>
        </Col>
      </Row>
      {(isError || invalidTicketFormat) && (
        <Row>
          {' '}
          <div className="text-danger">
            Error: {isError ? errorMsg : ''}
            {invalidTicketFormat
              ? t('dealerConfig.invalidTicketFormat', { HD_TICKET_FORMAT_STR })
              : ''}
          </div>
        </Row>
      )}
      <Row>
        <Col md={10}>
          <small className="fs-10" style={{ color: 'grey' }}>
            {t('licenseContent.format')}: XXXXX-XXXXX-XXXXX-XXXXX-XXXXX
          </small>
        </Col>
        <Col md={2} />
      </Row>
      <Row className="mb-30 d-flex align-items-center">
        <Col md={7}>
          <input
            type="text"
            className="form-control border"
            placeholder="Enter ticket Id"
            value={ticketId}
            onChange={(e) => {
              setTicketId(e.target.value);
              setInvalidTicketFormat(false);
            }}
          />
        </Col>
        <Col md={3} className="d-flex align-items-center">
          <Switch
            className="d-flex align-items-center"
            label={t('dealerConfig.initialCall')}
            id="isInitCall"
            defaultChecked={isInitialCall}
            onChange={(e) => setIsInitialCall(e.target.checked)}
          />
        </Col>
        <Col md={2}>
          <Button disabled={!ticketId} onClick={handleSubmit} className="w-100">
            View Result
          </Button>
        </Col>
      </Row>
      <Row className="mb-30">
        <Col>
          <Switch
            className="d-flex align-items-center"
            label={t('dealerConfig.wrapResponse')}
            id="wrapResponse"
            defaultChecked={wrapText}
            onChange={(e) => setWrapText(e.target.checked)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <pre className={wrapText ? 'text-wrap' : ''}>
            {!isLoading && !isError && data
              ? JSON.stringify(data, null, 2)
              : t('dealerConfig.response')}
          </pre>
        </Col>
      </Row>
    </Container>
  );
};
