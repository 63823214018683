import React, { useEffect, useState } from 'react';

import { authSlice, toastSlice } from 'slices';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { isFetchBaseQueryError } from 'utils/helpers';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useInviteAsDealerUserMutation } from 'thunks/RTKQuery/dealer';
import { IDealerItem } from 'pages/Shared/Dealer/Models/dealers';
import { InviteUsers } from './InviteUsers';

interface IDealerInviteUserContainer {
  selectedDealer?: IDealerItem;
  setSelectedDealer: (dealer: IDealerItem) => void;
  appendListViewData: (data: IDealerItem) => void;
}

export const InviteUsersContainer = ({
  selectedDealer,
  setSelectedDealer,
  appendListViewData,
}: IDealerInviteUserContainer) => {
  const dispatch = useAppDispatch();
  const { showInviteUserModel } = useAppSelector((state) => state.auth);
  const [saveError, setSaveError] = useState('');

  const [
    updateDealer,
    {
      isLoading: dealerUpdateLoading,
      error: dealerUpdateError,
      status: dealerUpdateStatus,
    },
  ] = useInviteAsDealerUserMutation();

  useEffect(() => {
    setSaveError('');
  }, [showInviteUserModel]);

  useEffect(() => {
    if (dealerUpdateError && isFetchBaseQueryError(dealerUpdateError)) {
      const msg = dealerUpdateError as any;
      setSaveError(`Error :${msg.data.message.errors}`);
    }
  }, [dealerUpdateError]);

  useEffect(() => {
    if (dealerUpdateStatus === QueryStatus.fulfilled) {
      dispatch(
        toastSlice.actions.show({
          title: 'Invite Send',
          message: 'Invitation has been sent successfully',
        })
      );
      dispatch(authSlice.actions.closeInviteUserModel());
    }
  }, [dealerUpdateStatus]);

  const onClose = () => {
    dispatch(authSlice.actions.closeInviteUserModel());
  };

  const handleSubmit = async (e: string) => {
    if (!selectedDealer) return;
    const props = 'id, name, dealerId, email, userId';
    const result: any = await updateDealer({
      invitedDealerId: selectedDealer?.id,
      email: e,
      props,
    });

    if (!result?.error) {
      const resultData = result?.data as IDealerItem;
      setSelectedDealer(resultData);
      appendListViewData(resultData);
    }
  };

  return (
    <>
      <InviteUsers
        show={showInviteUserModel}
        saveError={saveError}
        selectedDealer={selectedDealer}
        userSaveLoading={dealerUpdateLoading}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};

InviteUsersContainer.defaultProps = {
  selectedDealer: undefined,
};
