import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  LangIcon,
  OrderIcon,
  SettingsIcon,
  ClientIcon,
  ContractIcon,
  MessageIcon,
  CameraIcon,
  SupportIcon,
  SearchIcon,
} from 'icons';
import {
  MANAGER_HD_USAGE_INFO_ROUTE,
  MANAGER_MILLS_VIEW_ROUTE,
  MANAGER_ORDERS_VIEW_ROUTE,
  MANAGER_SETTINGS_ROUTE,
  MANAGER_USERS_VIEW_ROUTE,
  MANAGER_USER_ACTIVITY_ROUTE,
  MANAGER_DEALER_ACTIVITY_ROUTE,
  USER_MANAGEMENT_ACTIVITY_ROUTE,
  DEALER_CONFIG_RESULT_ROUTE,
} from 'constants/routes';

export const ManSidebar: React.FC = () => {
  return (
    <aside className="sidebar d-md-flex">
      <NavLink to={MANAGER_USERS_VIEW_ROUTE} className="sidebar-link">
        <ClientIcon />
      </NavLink>
      <NavLink to={MANAGER_MILLS_VIEW_ROUTE} className="sidebar-link">
        <LangIcon />
      </NavLink>
      <NavLink to={MANAGER_ORDERS_VIEW_ROUTE} className="sidebar-link">
        <OrderIcon />
      </NavLink>
      <NavLink to={MANAGER_HD_USAGE_INFO_ROUTE} className="sidebar-link">
        <ContractIcon />
      </NavLink>
      <NavLink to={MANAGER_USER_ACTIVITY_ROUTE} className="sidebar-link">
        <MessageIcon />
      </NavLink>
      <NavLink to={DEALER_CONFIG_RESULT_ROUTE} className="sidebar-link">
        <SearchIcon />
      </NavLink>
      <NavLink to={MANAGER_DEALER_ACTIVITY_ROUTE} className="sidebar-link">
        <CameraIcon />
      </NavLink>
      <NavLink to={USER_MANAGEMENT_ACTIVITY_ROUTE} className="sidebar-link">
        <SupportIcon />
      </NavLink>
      <NavLink to={MANAGER_SETTINGS_ROUTE} className="sidebar-link">
        <SettingsIcon />
      </NavLink>
    </aside>
  );
};
