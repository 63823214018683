export const ROOT_ROUTE = '/';
export const ABOUT_US_ROUTE = '/about-us';
export const CONTACT_ROUTE = '/contact';
export const DESKTOP_APP_ROUTE = '/desktop-app';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service';
export const DATA_AGREEMENT_ROUTE = '/data-processing-agreement';

export const MANAGER_HOME_ROUTE = '/manager/home';
export const MANAGER_MILLS_VIEW_ROUTE = '/manager/mills/view';
export const MANAGER_ORDERS_VIEW_ROUTE = '/manager/mill/orders/view';
export const MANAGER_USERS_VIEW_ROUTE = '/manager/users/view';
export const MANAGER_HD_USAGE_INFO_ROUTE = '/manager/hd/usage/info';
export const MANAGER_SETTINGS_ROUTE = '/manager/settings';
export const MANAGER_USER_ACTIVITY_ROUTE = '/manager/mill/activity';
export const MANAGER_DEALER_ACTIVITY_ROUTE = '/manager/dealer';
export const USER_MANAGEMENT_ACTIVITY_ROUTE = '/manager/user';
export const PUBLIC_LICENSE_CONTENT_ROUTE = '/license/info';
export const ACTIVE_LICENSES = `${PUBLIC_LICENSE_CONTENT_ROUTE}?tab=active-licenses`;
export const ORDERED_LICENSES = `${PUBLIC_LICENSE_CONTENT_ROUTE}?tab=ordered-licenses`;
export const LICENSE_DETAILS = `${PUBLIC_LICENSE_CONTENT_ROUTE}?tab=license-details`;

export const DEALER_HOME_ROUTE = '/dealer/home';
export const DEALER_INFORMATION_ROUTE = '/dealer/information';
export const DEALER_CONFIG_RESULT_ROUTE = '/dealer/config/result';

export const MILL_WELCOME_ROUTE = '/mill/welcome';
export const MILL_ORDERS_ROUTE = '/mills/projects';
export const MILL_COMPONENTS_ROUTE = `${MILL_ORDERS_ROUTE}/:projectId/components`;
export const MILL_COMPONENT_ROUTE = `${MILL_COMPONENTS_ROUTE}/:id`;
export const MILL_CLIENTS_ROUTE = '/mills/clients';
export const MILL_MESSAGES_ROUTE = '/mills/messages';
export const MILL_SETTINGS_ROUTE = '/mill/settings';
export const MILL_SETTINGS_LANDING_PAGE_ROUTE = `${MILL_SETTINGS_ROUTE}?tab=landing-page`;
export const MILL_SETTINGS_COMPANY_DETAILS_ROUTE = `${MILL_SETTINGS_ROUTE}?tab=company-details`;
export const MILL_SETTINGS_USERS_ROUTE = `${MILL_SETTINGS_ROUTE}?tab=users`;
export const MILL_SETTINGS_USERS_LICENSE_KEYS = `${MILL_SETTINGS_ROUTE}?tab=license-keys`;
export const MILL_SETTINGS_BILLING_ROUTE = `${MILL_SETTINGS_ROUTE}?tab=billing`;
export const MILL_SETTINGS_INVOICES_ROUTE = `${MILL_SETTINGS_ROUTE}?tab=invoices`;
export const MILL_ACCOUNT_ROUTE = '/mill/account';
export const MILL_ACCOUNT_PROFILE_ROUTE = `${MILL_ACCOUNT_ROUTE}?tab=profile`;
export const MILL_ACCOUNT_SETTINGS_ROUTE = `${MILL_ACCOUNT_ROUTE}?tab=settings`;
export const MILL_CLIENT_ROUTE = '/mill/client/:id/details';
export const MILL_CLIENT_COMPANY_INFO_ROUTE = `${MILL_CLIENT_ROUTE}?tab=company-info`;
export const MILL_CLIENT_ORDERS_ROUTE = `${MILL_CLIENT_ROUTE}?tab=orders`;
export const MILL_CLIENT_MESSAGES_ROUTE = `${MILL_CLIENT_ROUTE}?tab=messages`;

export const MILL_CONTRACT_ROUTE = '/mill/contract';
export const MILL_CONTRACT_TYPE_PAGE_ROUTE = `${MILL_CONTRACT_ROUTE}?tab=contract-types`;
export const MILL_CONTRACT_USAGE_DETAILS_ROUTE = `${MILL_CONTRACT_ROUTE}?tab=usage-details`;
export const MILL_CONTRACT_BILLING_ROUTE = `${MILL_CONTRACT_ROUTE}?tab=billing`;
export const MILL_CONTRACT_INVOICES_ROUTE = `${MILL_CONTRACT_ROUTE}?tab=invoices`;

export const STRIPE_PAYMENT_SUCCESS = '/payments/success';
export const STRIPE_PAYMENT_FAILURE = '/payments/failure';

export const ORDERS_PARTIAL_ROUTE = '/orders';
export const MESSAGES_PARTIAL_ROUTE = '/messages';

export const LAB_HOME_ROUTE = '/:millId/lab/home';
export const LAB_WELCOME_ROUTE = '/lab/welcome';
export const LAB_ORDERS_ROUTE = '/lab/projects';
export const LAB_COMPONENTS_ROUTE = `${LAB_ORDERS_ROUTE}/:projectId/components`;
export const LAB_COMPONENT_ROUTE = `${LAB_COMPONENTS_ROUTE}/:id`;
export const LAB_MESSAGES_ROUTE = '/lab/messages';
export const LAB_SUPPORT_ROUTE = '/lab/support';
export const LAB_SUPPORT_CONTACT_ROUTE = `${LAB_SUPPORT_ROUTE}?tab=contact`;
export const LAB_SUPPORT_DOWNLOAD_APP_ROUTE = `${LAB_SUPPORT_ROUTE}?tab=download-app`;
export const LAB_ACCOUNT_ROUTE = '/lab/account';
export const LAB_ACCOUNT_COMPANY_INFO_ROUTE = `${LAB_ACCOUNT_ROUTE}?tab=company-info`;
export const LAB_ACCOUNT_PROFILE_ROUTE = `${LAB_ACCOUNT_ROUTE}?tab=profile`;
export const LAB_ACCOUNT_SETTINGS_ROUTE = `${LAB_ACCOUNT_ROUTE}?tab=settings`;
