import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { authSlice } from 'slices';
import { InviteUsersContainer } from 'components/Modal/InviteUsers/InviteUsersContainer';
import { useTranslation } from 'react-i18next';
import { DealersDetailViewContainer } from 'pages/Shared/Dealer/DealerDetailView/DealersDetailViewContainer';
import { IDealerItem } from 'pages/Shared/Dealer/Models/dealers';
import { useBranchDealerSelf, useDealerRole, useManagerRole } from 'hooks/role';
import { ManagerDealerListViewContainer } from 'pages/Manager/DealersManagement/DealersListView';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import DealerLayout from 'components/Layout/Dealer/DealerLayout';
import { RootDealerListViewContainer } from '../../Dealer/RootDealerListViewContainer';

export const DealersView: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedDealer, setSelectedDealer] = useState<IDealerItem>();
  const [dealerListData, setDealerListData] = React.useState<IDealerItem[]>([]);
  const [rootDealer, setRootDealer] = React.useState<IDealerItem>();
  const [errorString, setErrorString] = useState('');
  const { t } = useTranslation();

  const isDealer = useDealerRole();
  const isManager = useManagerRole();
  const self = useBranchDealerSelf(selectedDealer?.userId);
  const createDealer = () => {
    setSelectedDealer(undefined);
  };

  const inviteUser = () => {
    dispatch(authSlice.actions.showInviteUserModel());
  };

  const appendListViewData = (data: IDealerItem) => {
    if (data) {
      const existingItemIndex = dealerListData.findIndex(
        (x) => x.id === data.id
      );
      if (existingItemIndex !== -1) {
        const updateData = dealerListData.filter((x) => x.id !== data.id);
        updateData.splice(existingItemIndex, 0, data);

        setDealerListData(updateData);
        return;
      }

      setDealerListData([data, ...dealerListData]);
    }
  };

  const Layout = isManager ? ManagerLayout : DealerLayout;

  return (
    <Layout>
      <h1 className="ml-2 mb-4 mt-3">{t('dealerManagement.heading')}</h1>
      <hr />
      <div className="d-flex w-100 justify-content-center page-contract-type mb-3">
        <Col md={4}>
          <div className="alert alert-error text-left font-size-sm">
            {errorString}
          </div>
        </Col>
        <Col md={2}>
          <Button
            disabled={!!self}
            onClick={inviteUser}
            className="btn-outline-primary invite-button"
          >
            {selectedDealer?.userId
              ? t('dealerManagement.reInvite')
              : t('dealerManagement.invitation')}
          </Button>
        </Col>
        <Col md={4} />

        <Col md={2}>
          <Button
            onClick={createDealer}
            className="btn-outline-primary create-button"
          >
            {t('dealerManagement.newUser')}
          </Button>
        </Col>
      </div>
      <div className="d-flex w-100 justify-content-center page-contract-type">
        <Col md={4} className="mr-20">
          {isDealer && (
            <RootDealerListViewContainer
              setErrorString={setErrorString}
              setSelectedDealer={setSelectedDealer}
              selectedDealer={selectedDealer}
              dealerListData={dealerListData}
              setDealerListData={setDealerListData}
              setRootDealer={setRootDealer}
            />
          )}
          {isManager && (
            <ManagerDealerListViewContainer
              setErrorString={setErrorString}
              setSelectedDealer={setSelectedDealer}
              selectedDealer={selectedDealer}
              dealerListData={dealerListData}
              setDealerListData={setDealerListData}
            />
          )}
        </Col>
        <Col md={8}>
          <DealersDetailViewContainer
            setErrorString={setErrorString}
            selectedDealer={selectedDealer}
            setSelectedDealer={setSelectedDealer}
            appendListViewData={appendListViewData}
            rootDealer={rootDealer}
          />
        </Col>
        <InviteUsersContainer
          selectedDealer={selectedDealer}
          setSelectedDealer={setSelectedDealer}
          appendListViewData={appendListViewData}
        />
      </div>
    </Layout>
  );
};
