import {
  HyperdentUsersGeoData,
  ManagerHome,
  ManagerSettings,
  MillView,
  OrdersView,
  UserActivityView,
  UsersView,
} from 'pages/Manager';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { DataProcessingAgreement } from 'pages/Public/DataProcessingAgreement';
import { LICENSE_CONTENT_SEARCH } from 'constants/constants';
import { LicenseContainer } from 'pages/Public/LicenseInfo';
import { UsersManagement } from 'pages/Manager/UserManagement';
import { DealerHome } from 'pages/Dealer/Home';
import { DealersView } from 'pages/Shared/Dealer';
import { DealerConfigResultManagerView } from 'pages/Manager/DealerConfigResultManagerView';
import { PrivateRouteContainer } from './components/Route/PrivateRouteContainer';
import {
  ABOUT_US_ROUTE,
  CONTACT_ROUTE,
  DESKTOP_APP_ROUTE,
  LAB_ACCOUNT_ROUTE,
  LAB_COMPONENT_ROUTE,
  LAB_HOME_ROUTE,
  LAB_MESSAGES_ROUTE,
  LAB_ORDERS_ROUTE,
  LAB_SUPPORT_ROUTE,
  LAB_WELCOME_ROUTE,
  MANAGER_HOME_ROUTE,
  MILL_ACCOUNT_ROUTE,
  MILL_CLIENTS_ROUTE,
  MILL_CLIENT_ROUTE,
  MILL_COMPONENT_ROUTE,
  MILL_MESSAGES_ROUTE,
  MILL_ORDERS_ROUTE,
  MILL_SETTINGS_ROUTE,
  MILL_WELCOME_ROUTE,
  PRIVACY_POLICY_ROUTE,
  ROOT_ROUTE,
  STRIPE_PAYMENT_FAILURE,
  STRIPE_PAYMENT_SUCCESS,
  TERMS_OF_SERVICE_ROUTE,
  MANAGER_ORDERS_VIEW_ROUTE,
  MANAGER_USERS_VIEW_ROUTE,
  MANAGER_MILLS_VIEW_ROUTE,
  MANAGER_HD_USAGE_INFO_ROUTE,
  MANAGER_SETTINGS_ROUTE,
  MILL_CONTRACT_ROUTE,
  DATA_AGREEMENT_ROUTE,
  MANAGER_USER_ACTIVITY_ROUTE,
  PUBLIC_LICENSE_CONTENT_ROUTE,
  MANAGER_DEALER_ACTIVITY_ROUTE,
  USER_MANAGEMENT_ACTIVITY_ROUTE,
  DEALER_HOME_ROUTE,
  DEALER_INFORMATION_ROUTE,
  DEALER_CONFIG_RESULT_ROUTE,
} from './constants/routes';
import {
  DEALER_ROLE,
  LAB_ROLE,
  MANAGER_ROLE,
  MILL_ADMIN_ROLE,
  MILL_ROLE,
} from './interfaces/user';
import { Components } from './pages/Components';
import { LabAccount, LabSupport, LabWelcomePage } from './pages/Lab';
import {
  Clients,
  MillAccount,
  MillClient,
  MillContract,
  MillSettings,
  PaymentFailurePage,
  PaymentSuccessPage,
  Welcome,
} from './pages/Mill';
import {
  AboutUs,
  Contact,
  DesktopApp,
  Home,
  HomeLab,
  PrivacyPolicy,
  TermsOfService,
} from './pages/Public';
import { SharedComponent, SharedMessages, SharedOrders } from './pages/Shared';

const App: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== PUBLIC_LICENSE_CONTENT_ROUTE) {
      const licenseKey = localStorage.getItem(LICENSE_CONTENT_SEARCH);
      if (licenseKey) {
        localStorage.removeItem(LICENSE_CONTENT_SEARCH);
      }
    }
  }, [location]);

  return (
    <Switch>
      <Route path={ROOT_ROUTE} component={Home} exact />
      <Route path={CONTACT_ROUTE} component={Contact} />
      <Route path={ABOUT_US_ROUTE} component={AboutUs} />
      <Route path={DESKTOP_APP_ROUTE} component={DesktopApp} />
      <Route path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />
      <Route path={TERMS_OF_SERVICE_ROUTE} component={TermsOfService} />
      <Route path={DATA_AGREEMENT_ROUTE} component={DataProcessingAgreement} />
      <Route path={LAB_HOME_ROUTE} component={HomeLab} />
      <PrivateRouteContainer
        path={MILL_WELCOME_ROUTE}
        component={Welcome}
        userRole={MILL_ROLE}
        millRole={MILL_ADMIN_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_COMPONENT_ROUTE}
        component={SharedComponent}
        userRole={MILL_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_ORDERS_ROUTE}
        component={SharedOrders}
        userRole={MILL_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_CLIENTS_ROUTE}
        component={Clients}
        userRole={MILL_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_MESSAGES_ROUTE}
        component={SharedMessages}
        userRole={MILL_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_SETTINGS_ROUTE}
        component={MillSettings}
        userRole={MILL_ROLE}
        millRole={MILL_ADMIN_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_CONTRACT_ROUTE}
        component={MillContract}
        userRole={MILL_ROLE}
        millRole={MILL_ADMIN_ROLE}
      />
      <PrivateRouteContainer
        path={STRIPE_PAYMENT_SUCCESS}
        component={PaymentSuccessPage}
        userRole={MILL_ROLE}
        millRole={MILL_ADMIN_ROLE}
      />
      <PrivateRouteContainer
        path={STRIPE_PAYMENT_FAILURE}
        component={PaymentFailurePage}
        userRole={MILL_ROLE}
        millRole={MILL_ADMIN_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_ACCOUNT_ROUTE}
        component={MillAccount}
        userRole={MILL_ROLE}
      />
      <PrivateRouteContainer
        path={MILL_CLIENT_ROUTE}
        component={MillClient}
        userRole={MILL_ROLE}
      />
      <PrivateRouteContainer
        path={LAB_WELCOME_ROUTE}
        component={LabWelcomePage}
        userRole={LAB_ROLE}
      />
      <PrivateRouteContainer
        path={LAB_COMPONENT_ROUTE}
        component={SharedComponent}
        userRole={LAB_ROLE}
      />
      <PrivateRouteContainer
        path={LAB_ORDERS_ROUTE}
        component={SharedOrders}
        userRole={LAB_ROLE}
      />
      <PrivateRouteContainer
        path={LAB_MESSAGES_ROUTE}
        component={SharedMessages}
        userRole={LAB_ROLE}
      />
      <PrivateRouteContainer
        path={LAB_ACCOUNT_ROUTE}
        component={LabAccount}
        userRole={LAB_ROLE}
      />
      <PrivateRouteContainer
        path={LAB_SUPPORT_ROUTE}
        component={LabSupport}
        userRole={LAB_ROLE}
      />
      <Route path="/components" component={Components} />
      <PrivateRouteContainer
        path={MANAGER_HOME_ROUTE}
        component={ManagerHome}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={MANAGER_MILLS_VIEW_ROUTE}
        component={MillView}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={MANAGER_ORDERS_VIEW_ROUTE}
        component={OrdersView}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={MANAGER_USERS_VIEW_ROUTE}
        component={UsersView}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={DEALER_CONFIG_RESULT_ROUTE}
        component={DealerConfigResultManagerView}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={MANAGER_DEALER_ACTIVITY_ROUTE}
        component={DealersView}
        userRole={MANAGER_ROLE}
      />

      <PrivateRouteContainer
        path={USER_MANAGEMENT_ACTIVITY_ROUTE}
        component={UsersManagement}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={MANAGER_HD_USAGE_INFO_ROUTE}
        component={HyperdentUsersGeoData}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={MANAGER_SETTINGS_ROUTE}
        component={ManagerSettings}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={MANAGER_USER_ACTIVITY_ROUTE}
        component={UserActivityView}
        userRole={MANAGER_ROLE}
      />
      <PrivateRouteContainer
        path={DEALER_HOME_ROUTE}
        component={DealerHome}
        userRole={DEALER_ROLE}
      />
      <PrivateRouteContainer
        path={DEALER_INFORMATION_ROUTE}
        component={DealersView}
        userRole={DEALER_ROLE}
      />
      <Route path={PUBLIC_LICENSE_CONTENT_ROUTE} component={LicenseContainer} />
      <Route path="/*" component={Home} />
    </Switch>
  );
};

export default App;
