import React, { useEffect } from 'react';
import { DealerListViewContainer } from 'pages/Shared/Dealer/DealerListView/DealersListViewContainer';
import { useGetDealerListQuery } from 'thunks/RTKQuery/dealer';
import { Spinner2 } from 'components';
import { IDealerItem } from 'pages/Shared/Dealer/Models/dealers';

interface IManagerDealerListViewContainer {
  setErrorString: (err: string) => void;
  setSelectedDealer: (dealer: IDealerItem) => void;
  selectedDealer?: IDealerItem;
  dealerListData: IDealerItem[];
  setDealerListData: (data: IDealerItem[]) => void;
}

export const ManagerDealerListViewContainer: React.FC<IManagerDealerListViewContainer> = ({
  setErrorString,
  setSelectedDealer,
  selectedDealer,
  dealerListData,
  setDealerListData,
}: IManagerDealerListViewContainer) => {
  const params = 'id, name, dealerId, email, userId';

  const {
    data: dealerData = [],
    isLoading: isdealerDataLoading,
    error: dealerDataError,
  } = useGetDealerListQuery(params);

  useEffect(() => {
    if (!dealerData) return;
    setDealerListData(dealerData);
  }, [dealerData]);

  useEffect(() => {
    if (dealerDataError && 'message' in dealerDataError) {
      setErrorString(`Error :${dealerDataError.message}`);
    }
  }, [dealerDataError]);

  return (
    <>
      <Spinner2 show={isdealerDataLoading} />
      <DealerListViewContainer
        setSelectedDealer={setSelectedDealer}
        dealerData={dealerListData}
        selectedDealer={selectedDealer}
      />
    </>
  );
};

ManagerDealerListViewContainer.defaultProps = { selectedDealer: undefined };
