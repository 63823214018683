export default {
  formats: {
    date: {
      short: 'DD.MM.YYYY',
      long: 'DD.MM.YYYY HH:mm',
      describe: 'MMMM Do YYYY, h:mm A',
    },
  },
  language: {
    de: 'Deutsch',
    en: 'English',
    it: 'Italienisch',
    fr: 'Französisch',
    sp: 'Spanisch',
    pl: 'Polnisch',
  },
  search: 'Suchen',
  goBack: 'Zurück',
  signIn: {
    title: 'Anmelden.',
    dontHaveAccount: 'Noch kein Account?',
  },
  signUp: {
    title: 'Registrieren',
    info:
      'Mit Ihrer Anmeldung erklären Sie sich mit unseren <0>{{privacy}}</0> und <1>{{terms}}</1> einverstanden.',
    promotions: 'Ich möchte keine E-Mail-Updates oder Werbeaktionen erhalten',
    alreadyHaveAccount: 'Account bereits vorhanden?',
    serviceterms: 'Ich stimme zu <0>{{service}}</0>.',
    dataprocessing: 'Ich stimme den  <0>{{agreement}}</0> zu.',
  },
  signOut: {
    title: 'Abmelden',
  },
  email: {
    label: 'Email Adresse',
    placeholder: 'Email Adresse',
    taken: 'E-Mail Adresse bereits vorhanden',
  },
  password: {
    label: 'Passwort',
    placeholder: 'Passwort eingeben.',
    mismatch: 'Passwort muss gleich sein.',
  },
  passwordConfirmation: {
    label: 'Passwort bestätigen',
    placeholder: 'Bitte Passwort bestätigen',
  },
  firstName: {
    label: 'Vorname',
    placeholder: 'Bitte Vornamen eingeben',
  },
  lastName: {
    label: 'Nachname',
    placeholder: 'Bitte Nachnamen eingeben.',
  },
  activationKey: {
    label: 'Aktivierungs-Code',
    placeholder: 'Bitte Aktivierungscode eingeben',
  },
  companyName: {
    label: 'Firmen-Name',
    placeholder: 'Bitte Firmennamen eingeben',
  },
  contactPerson: {
    label: 'Kontaktperson',
    placeholder: 'Bitte Kontaktperson angeben',
  },
  country: {
    label: 'Land',
  },
  address: {
    label: 'Adresse',
    placeholder: 'Adresse hinzufügen',
  },
  city: {
    label: 'Stadt',
    placeholder: 'Stadt hinzufügen',
  },
  zip: {
    label: 'Postleitzahl',
    placeholder: 'Bitte Postleitzahl angeben',
  },
  web: {
    label: 'Web',
    placeholder: 'Bitte Website angeben',
  },
  uid: {
    label: 'Steuernummer',
    placeholder: 'Bitte Steuernummer angeben',
  },
  vatId: {
    label: 'Steuernummer',
    placeholder: 'Steuernummer (z.B E123456789)',
  },
  state: {
    label: 'Land',
    placeholder: 'Bitte Land eingeben',
  },
  street: {
    label: 'Straße',
    placeholder: 'Lieferadresse hinzufügen( Straße )',
  },
  phone: {
    label: 'Telefon',
    placeholder: 'Bitte Telefonnummer angeben',
  },
  forgot: {
    title: 'Passwort vergessen?',
    info:
      'Keine Sorge, bitte die E-Mail Adresse angeben und ein Link zum Zurücksetzen des Passwortes erhalten.',
    button: 'Passwort zurücksetzen.',
    password: 'Passwort vergessen?',
  },
  header: {
    profile: 'Profil',
    settings: 'Einstellungen',
    companyInfo: 'Firmen-Informationen',
    selectMill: 'Bitte Fräszentrum auswählen',
  },
  footer: {
    menu: {
      aboutUs: {
        title: 'Über uns',
        content:
          '<strong>Follow-Me! Technologieführer entwickeln wir mit Blick auf die Zukunft immer wieder neue intelligente Lösungen für unser Produktportfolio. Mit diesen Lösungen berücksichtigen wir die zukünftigen Anforderungen und Erwartungen unserer Kunden und Partner. Als Qualitätsführer legen wir großen Wert auf eine 100-prozentige Qualität bestehender Funktionen und legen die gleichen hohen Maßstäbe bei der Entwicklung neuer Funktionen an. Wir stellen unsere Servicekompetenz jeden Tag aufs Neue unter Beweis. Wir haben immer ein offenes Ohr für unsere Kunden und unser lösungsorientierter Ansatz führt zu einer raschen Lösung kritischer Punkte. Persönliche Betreuung und Weiterbildung sind uns sehr wichtig. Wir bauen unser Trainingsprogramm, d.h. Schulungen und Webinare, weiter aus, um unsere Kunden in die Lage zu versetzen, selbstständig zu arbeiten.</strong>',
        ceoMessage:
          '“Wir möchten dentale Softwarelösungen anbieten, die Ihnen einen Mehrwert schaffen, indem sie Produktionsprozesse durch Digitalisierung und Automatisierung verbessern.”',
      },
      contact: {
        title: 'Kontakt',
        content:
          '<strong>FOLLOW-ME! TECHNOLOGY GmbH, Regerstrasse 27, 81541 Munich, Germany. <br> Tel.: +49 89 452 170 60 .<br> E-Mail: info@follow-me-tech.com</strong>',
      },
      desktopApp: {
        title: 'Service Anwendung',
        content:
          '<strong>Laden Sie die Desktop-Anwendung hier herunter</strong>',
      },
      privacyPolicy: {
        title: 'Datenschutzbestimmungen',
        content:
          '<strong>Datenschutz hat einen sehr hohen Stellenwert für die FOLLOW-ME! Technology GmbH. Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Personenbezogene Daten werden nur insoweit verarbeitet, als dies für die Bereitstellung einer funktionsfähigen Website und der darüber angebotenen Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt in der Regel nur nach vorheriger Einwilligung des Nutzers. Ausgenommen hiervon sind Fälle, in denen eine vorherige Einholung der Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten nach den gesetzlichen Bestimmungen zulässig ist.</strong>',
      },
      termsOfService: {
        title: 'Nutzungsbedingungen',
        content:
          '<strong>Wir arbeiten aktuell an einer aktualisierten Version der Servicebestimmungen von hyperDENT Connect.</strong>',
      },
      dataProcessing: {
        title: 'Datenverarbeitungs-Vereinbarung',
        content:
          '<strong>Wir arbeiten derzeit an unserer aktualisierten Datenverarbeitungsvereinbarung, bitte schauen Sie später nach, um die aktualisierte Datenverarbeitungsvereinbarung für die Nutzung von hyperDENT Connect zu sehen.</strong>',
      },
    },
  },
  home: {
    home: 'Start',
    subtitle: 'hyperDENT Connect',
    title: 'Connected with Experts',
    content: 'Austausch digitaler Fertigungsdaten leicht gemacht.',
    reasons: {
      title: 'Warum hyperDENT Connect?',
      reason1: {
        title: 'Die digitale Zahnmedizin verändert die Anforderungen. ',
        content:
          'Höhere Produktivität, mehr Flexibilität, mehr Speicherplatz und schlankere Prozesse.',
      },
      reason2: {
        title: 'Passen sie Ihre Cloud-Services an.',
        content:
          'Maßgeschneiderte, leistungsstarke und skalierbare Cloud-Services eröffnen Fräszentren völlig neue Möglichkeiten, die eigenen Ziele zu erreichen.',
      },
      reason3: {
        title: 'Einfacher Cloud-Zugang. ',
        content:
          'Mit hyperDENT Connect können Sie auf die Daten Ihrer Kunden zugreifen und die Anwendung von jedem Gerät aus nutzen, das Zugang zum Internet hat.',
      },
    },
    policiesupdate: {
      title: 'Aktualisierungen der Bestimmungen',
      content:
        'Wir benötigen Ihre Zustimmung zu unseren aktualisierten Nutzungsbedingungen und der Vereinbarung zur Datenverarbeitung.',
    },
  },
  welcome: {
    title: 'Willkommen bei hyperDENT Connect',
    content:
      'hyperDENT Connect ist Ihre Plattform für den Austausch digitaler Dentalakten. Mühelose Freigabe für Fräszentrumskunden, Echtzeit-Statusbericht und einfache Kommunikation verbindet Fräszentren mit ihren Kunden. ',
    landingPage: {
      title: '1. Startseite erstellen',
      content: '',
      button: 'Landingpage Einstellungen',
    },
    inviteClients: {
      title: '2. Kunden einladen',
      content:
        'Fügen Sie Labore und Praxen Ihrem Account zu. Nutzen Sie dazu den Einladungslink. ',
      button: 'Kunden einladen',
    },
  },
  labWelcome: {
    title: 'Anleitungs-Text',
    content: 'Anleitungs-Überschrift: Text hier',
    serviceApp: {
      title: 'Service Anwendung',
      content: '',
      button: 'App herunterladen',
    },
    dashboard: {
      title: 'Inhaltsverzeichnis',
      content: '',
      button: 'Zur Übersicht',
    },
  },
  orders: {
    title: 'Aufträge',
    deleted: 'Gelöscht',
    deletedFilesInfo:
      'Die Dateien für dieses Projekt wurden gelöscht, da das Limit für die Aufbewahrung von Dateien erreicht wurde.',
    noSearch: 'Keine Suchergebnisse',
    noOrders: 'Keine Aufträge erstellt',
    winApp: 'Aufträge werden über die Windows Anwendung erstellt',
    downloadApp: 'Anwendung herunterladen',
    columns: {
      orderId: 'Auftrags-Nummer',
      name: 'Auftrags-Name',
      date: 'Datum',
      messages: 'Nachrichten',
      components: 'Komponenten',
      client: 'Kunde',
      responsible: 'Projekt-Verantwortlich',
      download: 'Download',
      report: 'Bericht',
    },
    component: {
      columns: {
        name: 'Komponente',
        status: 'Status',
        messages: 'Nachrichten',
        files: 'Daten',
        comment: 'Kommentar',
      },
    },
    messages: 'Nachrichten',
    message: 'Nachrichten',
    newMessage: 'neu',
    select: {
      any: {
        client: 'Alle Kunden',
        responsible: 'Alle Anwender',
      },
    },
  },
  project: {
    download: {
      flat: 'flach',
      hierarchical: 'Hierarchisch',
    },
  },
  component: {
    orders: 'Aufträge',
    component: 'Komponente',
    status: {
      label: 'Komponenten Status',
      any: 'Jeder Status',
      open: 'Offen',
      closed: 'Geschlossen',
      downloaded: 'Heruntergeladen',
    },
    orderId: 'Auftrags ID',
    project: 'Projekt',
    preview: 'Komponenten-Vorschau',
    documents: 'Komponenten Dokumente',
    downloadAll: 'Alle herunterladen',
    downloads: 'Dokumente zum Download',
    uploadPdf: 'Pdf hochladen',
    noFiles: 'Keine Daten',
    messages: {
      title: 'Nachrichten',
      message: {
        placeholder: 'Nachricht senden',
      },
      button: 'Nachricht senden',
    },
    upload: {
      title: 'Dokument hinzufügen',
      name: {
        label: 'Dokumenten-Name',
        placeholder: 'Bitte Dokumentenname eingeben',
      },
      button: 'Hochladen',
    },
    download: {
      deleteConfirm: {
        title: 'Dokument löschen',
        message: 'Sind Sie sicher, dass Sie dieses Dokument löschen wollen?',
        buttonOk: 'Löschen',
        buttonCancel: 'Abbrechen',
      },
      deleteToast: {
        title: 'Dokument löschen',
        message: 'Dokument gelöscht',
      },
      uploadToast: {
        title: 'Dokument hochladen',
        message: 'Dokument erfolgreich hochgeladen',
      },
    },
    updateToast: {
      title: 'Komponente aktualisieren',
      message: 'Komponente wurde erfolgreich aktualisiert.',
    },
    messageCreateToast: {
      title: 'Nachricht senden',
      message: 'Nachricht geschickt',
    },
    updateOrderToast: {
      title: 'Aufträge aktualisieren',
      message: 'Auftrag wurde erfolgreich aktualisiert.',
    },
  },
  fileUpload: {
    label: 'Hochladen durch Ziehen und Ablegen oder Klicken',
  },
  clients: {
    title: 'Kunden',
    addClient: 'Kunden hinzufügen',
    status: {
      label: 'Status',
      any: 'Jeder Status',
      active: 'Aktiv',
      inactive: 'Inaktiv',
    },
    columns: {
      company: 'Firma',
      email: 'Email',
      lab: 'Labor-Name',
      contactPerson: 'Kontaktperson',
      status: 'Status',
    },
    actions: {
      sendInvite: 'Einladung senden',
      delete: 'Löschen',
      blocked: 'Blockiert',
      unBlock: 'Freigeben',
    },
    deleteConfirm: {
      title: 'Kunden löschen',
      message: 'Diesen Kunden löschen?',
      buttonOk: 'Löschen',
      buttonCancel: 'Abbrechen',
    },
    blockedConfirm: {
      title: 'Kunden blockieren',
      message: 'Sind Sie sicher, dass Sie den Kunden blockieren wollen?',
      buttonOk: 'Blockieren',
      buttonCancel: 'Abbrechen',
    },
    unblockedConfirm: {
      title: 'Kunden entsperren',
      message: 'Sind Sie sicher das Sie diesen Kunden freigeben wollen?',
      buttonOk: 'Freigeben',
      buttonCancel: 'Abbrechen',
    },
    deleteToast: {
      title: 'Kunden Löschen',
      message: 'Kunde gelöscht.',
    },
    blockToast: {
      title: 'Kunden blockieren',
      message: 'Kunde erfolgreich blockiert',
    },
    unblockToast: {
      title: 'Kunden entsperren',
      message: 'Kunde erfolgreich freigegeben',
    },
    blockNotAllowedToast: {
      title: 'Kunden entsperren',
      message:
        'Dieser Kunde wurde vom System gesperrt. Bitte kontaktieren Sie Ihren Admin',
    },
    client: {
      title: 'Kiunden hinzufügen',
      button: 'Kunden hinzufügen',
    },
    activation: {
      title: 'Aktivierungs-Anleitung',
      info:
        'Kunde erfolgreich hinzugefügt. Sie können diese Nachrichtenvorlage kopieren und verwenden, um den neu hinzugefügten Kunden zu kontaktieren.',
      message: {
        label: 'Nachricht',
        placeholder: 'Bitte Einladungstext eingeben',
      },
      copy: 'Nachricht kopieren',
      send: 'E-Mail senden',
      confirm: {
        title: 'Anleitungs-Mail',
        message: 'Nachricht mit Anleitungs-Text versendet',
        buttonOk: 'Schließen',
      },
      errorConfirm: {
        title: 'Fehler bei der Aktivierungs-Anleitung',
        buttonOk: 'OK',
      },
    },
    millingcenterClientAdded: {
      title: 'Fräszentrumskunde hinzugefügt',
      message:
        "Der Fräszentrumskunde '{{millClient}}' wurde dem Arbeitsbereich hinzugefügt.",
      buttonOk: 'Gut',
    },
  },
  messages: {
    title: 'Nachrichten',
    status: {
      any: 'Alle Nachrichten',
      read: 'Lesen',
      unread: 'Ungelesen',
    },
    noMessages: 'Sie haben keine neuen Nachrichten',
  },
  settings: {
    title: 'Einstellungen',
    tabs: {
      landingPage: 'Hauptseite',
      companyDetails: 'Firmen-Details',
      users: 'Anwender',
      licenseKeys: 'Lizenzschlüssel',
    },
    company: {
      logo: {
        title: 'Firmen-Logo',
        label: 'Logo',
      },
      info: {
        title: 'Firmen-Infos',
      },
      button: 'Update',
      message: {
        update: 'Bitte Firmen-Details updaten.',
      },
      error: {
        title: 'Fehler aufgetreten',
        message: 'Beim Speichern der Startseite ist ein Fehler aufgetreten.',
      },
    },
    users: {
      add: 'Anwender hinzufügen',
      edit: 'Anwender anpassen',
      roles: {
        title: 'Rollen',
        all: 'Alle Rollen',
        admin: 'Admin',
        user: 'Anwender',
      },
      columns: {
        name: 'Name',
        email: 'E-Mail',
        role: 'Rolle',
      },
      actions: {
        delete: 'Löschen',
        edit: 'Anpassen',
      },
      deleteConfirm: {
        title: 'Nutzer löschen',
        message: 'Sind Sie sicher das Sie den Anwender löschen möchten?',
        buttonOk: 'Löschen',
        buttonCancel: 'Abbrechen',
      },
      addToast: {
        title: 'Nutzer hinzufügen',
        message: 'Anwender erfolgreich hinzugefügt',
      },
      editToast: {
        title: 'Anwender anpassen',
        message: 'Anwender erfolgreich aktualisiert.',
      },
      deleteToast: {
        title: 'Nutzer löschen',
        message: 'Anwender erfolgreich entfernt.',
      },
      error: {
        title: 'Fehler aufgetreten',
        message: 'Beim Speichern der Nutzerdetails ist ein Fehler aufgetreten.',
      },
    },
    licenseKey: {
      add: 'Lizenzschlüssel hinzufügen',
      columns: {
        licenseKey: 'Lizenzschlüssel',
        placeholder: 'Lizenzschlüssel eingeben',
        enableCollection: 'Datensammlung erlauben',
      },
      actions: {
        delete: 'Löschen',
      },
      deleteConfirm: {
        title: 'Lizenzschlüssel löschen',
        message: 'Sind Sie sicher das Sie den Lizenzschlüssel löschen möchten?',
        buttonOk: 'Löschen',
        buttonCancel: 'Abbrechen',
      },
      addToast: {
        title: 'Lizenzschlüssel hinzufügen',
        message: 'Lizenzschlüssel hinzugefügt.',
      },
      editToast: {
        title: 'Lizenzschlüssel anpassen',
        message: 'Lizenzschlüssel erfolgreich aktualisiert.',
      },
      deleteToast: {
        title: 'Lizenzschlüssel löschen',
        message: 'Lizenzschlüssel erfolgreich entfernt.',
      },
      error: {
        title: 'Fehler aufgetreten',
        message:
          'Beim Speichern des Lizenzschlüssels ist ein Fehler aufgetreten.',
      },
    },
    landingPage: {
      intro: 'Startseite konfigurieren.',
      copy: {
        label: 'URL kopieren',
        button: 'Kopieren',
      },
      logo: 'Logo',
      header: {
        label: 'Überschrift',
        placeholder: 'Überschrift erstellen',
      },
      content: {
        label: 'Inhalt',
        placeholder: 'Beschreibung erstellen',
      },
      update: 'Update',
      preview: {
        btnTxt: 'Vorschau der Startseite',
        closeBtnTxt: 'Vorschau schließen',
        signIn: 'Anmelden',
      },
      logoAlt: 'Firmen-Logo',
      notify: {
        title: 'Startseite aktualisieren',
        message: 'Startseite noch nicht überprüft. Möchten Sie dies jetzt tun?',
        okText: 'Weiter',
        cancelText: 'Überspringen',
      },
    },
  },
  imageUpload: {
    button: 'Durchsuchen',
  },
  countries: {
    empty: 'Wählen Sie das Land',
    de: 'Deutschland',
    us: 'USA',
    es: 'Spanien',
  },
  support: {
    title: 'Support',
    tabs: {
      contact: 'Kontakt',
      downloadApp: 'App herunterladen',
    },
    contact: {
      info: 'Wie können wir helfen?',
      title: 'Schreiben Sie uns',
      topic: {
        label: 'Thema',
        placeholder: 'Was ist Ihr Anliegen?',
      },
      topics: {
        empty: 'Wählen Sie ein Anliegen',
        topicA: 'Anliegen A',
        topicB: 'Anliegen B',
        topicC: 'Anliegen C',
      },
      message: {
        label: 'Nachricht',
      },
      submit: 'Einreichen',
      callUs: 'Rufen Sie uns an',
      line: 'Support-Leitungen',
      phone: 'Tel.: +49 89 452 170 60',
      fax: 'Fax.: +49 89 452 170 69',
      sendEmailToast: {
        title: 'Kontakt Email',
      },
    },
    downloadApp: {
      info: 'Desktop-Anwendung für Windows',
      download: 'Download',
      toastTitle: 'App herunterladen',
      notFound: 'Anwendung nicht verfügbar',
    },
  },
  account: {
    title: 'Account',
    tabs: {
      company: 'Firmen-Informationen',
      settings: 'Einstellungen',
      profile: 'Profil',
    },
    company: {
      companyInfo: 'Erstellen Sie Ihr Firmen-Profil',
      logo: 'Firmen-Logo',
      information: 'Info',
      contact: 'Kontakt',
      address: 'Adresse',
      shipping: {
        title: 'Lieferung',
        address: 'Versand-Adresse',
        sameAsAddress: 'Gleiche wie Adresse',
        street: {
          label: 'Straße',
          placeholder: 'Lieferadresse hinzufügen ( Straße )',
        },
        city: {
          label: 'Stadt',
          placeholder: 'Geben Sie die Stadt ein',
        },
        zip: {
          label: 'Postleitzahl',
          placeholder: 'Postleitzahl ( Lieferadresse )',
        },
      },
      button: 'Aktualisieren',
    },
    settings: {
      info: 'Persönliche Einstellungen anpassen.',
      password: {
        title: 'Passwort',
        label: 'Neues Passwort',
        placeholder: 'Neues Passwort eingeben',
      },
      passwordConfirmation: {
        label: 'Passwort bestätigen',
        placeholder: 'Passwort tippen und bestätigen',
      },
      updatePassword: 'Passwort aktualisieren',
      notifications: {
        title: 'Benachrichtigung',
        notificationOnNewMessageReceived: 'Neue Nachricht empfangen',
        sendEmailOnNewMessageReceived: 'Email auf neue Nachricht',
        notificationOnNewProjectIsCreated: 'Neuer Auftrag erstellt',
        sendEmailOnNewProjectIsCreated: 'Email oder neuer Auftrag',
        notificationOnOrderProcessStart: 'Bestellung ausgelöst',
        notificationOnOrderProcessCompleted: 'Bestellung abgeschlossen',
      },
      language: {
        title: 'Sprache',
      },
      project: {
        title: 'Projekt',
        folderStructure: 'Ordnerstruktur herunterladen',
      },
    },
    profile: {
      info: 'Erstellen Sie Ihre Profil-Informationen',
      button: 'Aktualisieren',
    },
    updateProfileToast: {
      title: 'Profil aktualisieren',
      message: 'Profil erfolgreich aktualisiert',
    },
    updatePasswordToast: {
      title: 'Passwort aktualisieren',
      message: 'Passwort erfolgreich aktualisiert',
    },
    updateNotificationToast: {
      title: 'Update Benachrichtigung',
      message: 'Benachrichtigung erfolgreich aktualisiert',
    },
    updateLanguageToast: {
      title: 'Sprache aktualisieren',
      message: 'Sprache erfolgreich aktualisiert',
    },
    updateCompanyToast: {
      title: 'Firma aktualisieren',
      message: 'Firma erfolgreich aktualisiert',
    },
    invalidVAT: {
      title: 'Ungültige USt Nummer',
      message:
        'Die Steuernummer ist inkorrekt. Bitte geben Sie eine gültige Steuernummer ein.',
    },
  },
  switch: {
    on: 'An',
    off: 'Aus',
  },
  auth: {
    registerConfirm: {
      title: 'Bestätigungs-Link',
      message:
        'Wir haben Ihnen einen Bestätigungs-Link per Email gesendet. Bitte prüfen Sie Ihren Posteingang und aktivieren Sie Ihren Zugang.',
      buttonOk: 'OK',
    },
    confirmationConfirm: {
      title: 'Registrieren',
      message:
        'Willkommen {{name}}! Ihr Zugang ist jetzt aktiv. Sie haben nun Zugang zu unserer Plattform.',
      buttonOk: 'Gut',
    },
    forgotConfirm: {
      title: 'Bestätigung für vergessenes Passwort',
      message:
        'Zum Zurücksetzen Ihres Passwortes prüfen Sie Ihren Email-Eingang.',
    },
    resetConfirm: {
      title: 'Bestätigung der Passwortrücksetzung',
      message: 'Ihr Passwort wurde erfolgreich geändert.',
    },
    accountCreationConfirm: {
      title: 'Registrierung erfolgreich',
      error: 'Fehler bei der Registrierung',
      message:
        'Willkommen {{name}}! Ihr Zugang ist jetzt aktiv. Sie haben nun Zugang zu unserer Plattform.',
      buttonOk: 'Gut',
    },
    millCenterAdded: {
      title: 'Fräszentrum hinzugefügt',
      message: "Sie sind dem  '{{millName}}' Fräszentrum beigetreten.",
      buttonOk: 'Gut',
    },
  },
  reset: {
    title: 'Passwort zurücksetzen',
    info: 'Bitte neues Passwort eingeben',
    button: 'Passwort ändern',
  },
  accountCreation: {
    title: 'Zugang einrichten',
    info: 'Bitte Passwort eingeben, um Ihren Account zu erstellen.',
    password: {
      label: 'Passwort erstellen',
      placeholder: 'Passwort eingeben',
    },
    button: 'Account aktivieren',
  },
  invitation: {
    title: 'Einladung',
    emailLabel: 'E-Mail',
    message: "Sie wurden zum  '{{millName}}' Fräszentrum eingeladen.",
    submitBtnText: 'Neuen Account erstellen',
    connectToAccount: 'Mit bestehendem Account verbinden',
    connectToAccountMsg:
      'Wenn Sie bereits über ein hyperDENT Connect-Konto verfügen, können Sie diesen Arbeitsbereich zu diesem Konto hinzufügen.',
    or: 'oder',
    addMillToAccountTxt: 'Fräszentrum zum Account hinzufügen',
    createNewAccount: 'Neuen Account erstellen',
    sendConfirmationEmail: 'Bestätigungs-Mail senden',
    existingAccount: 'Bestehender Account',
    existingAccountMsg:
      'Die von Ihnen eingegebene E-Mail-Adresse ist mit einem bestehenden hyperDENT Connect-Konto verknüpft. Sie können ein neues Fräszentrum zu diesem Konto hinzufügen oder ein neues Konto mit einer anderen E-Mail-Adresse erstellen.',
  },
  landingPage: {
    signIn: 'Anmelden',
  },
  unsavedChanges: {
    message:
      'Sie haben nicht gespeicherte Änderungen, sind Sie sicher, dass Sie fortfahren wollen?',
  },
  contract: {
    title: 'Vertrag',
    error: 'Fehler aufgetreten',
    tabs: {
      contractType: {
        title: 'Vertragsart',
        subscribe: 'Abonnieren',
        unsubscribe: 'Abonnement beenden',
        resubscribe: 'Neuanmeldung',
        addOnConfirmation:
          'Die Aufbewahrungsdauer der Dateien für {{addOnDuration}} Tage (€{{getPromotionalPrice}}/Monat) wird zu Ihrem Abonnement hinzugefügt und in der nächsten Rechnung berechnet.',
        addOnUnsubscription:
          'Basic Add-On mit minimaler Dateiaufbewahrungsdauer wird mit Ihrem Plan ausgewählt.',
        subscriptionSuccess:
          'Sie haben den von Ihnen gewählten Tarif erfolgreich abonniert',
        subscriptionFailure:
          'Bei der Anmeldung zu dem von Ihnen gewählten Tarif ist ein Fehler aufgetreten. Bitte kontaktieren Sie Ihren Administrator oder versuchen Sie es später erneut',
        unsubscriptionSuccess:
          'Sie haben sich erfolgreich von Ihrem gewählten Tarif abgemeldet',
        unsubscriptionFailure:
          'Bei der Abmeldung von Ihrem ausgewählten Plan ist ein Fehler aufgetreten. Bitte kontaktieren Sie Ihren Administrator oder versuchen Sie es später erneut.',
        resubscriptionSuccess:
          'Sie haben Ihren gewählten Tarif erfolgreich wieder aufgenommen',
        resubscriptionFailure:
          'Bei der Wiederaufnahme Ihres ausgewählten Tarifs ist ein Fehler aufgetreten. Bitte kontaktieren Sie Ihren Administrator oder versuchen Sie es später noch einmal.',
        month: 'Monat',
        cancelsAt: 'Abonnement endet am: ',
        free: 'Gratis',
        silver: 'Silber',
        gold: 'Gold',
        basic: 'Basic',
        essential: 'Essential',
        enterprise: 'Enterprise',
        custom: 'Benutzerdefiniert',
        storage: '{{dataLimit}}Mbs an Cloud-Speicher',
        orders: 'Erstellen Sie bis zu {{ordersLimit}} Aufträge',
        clients: 'Laden Sie bis zu {{clients}} Kunden ein',
        supportAvailable: 'Support ist verfügbar',
        supportNotAvailable: 'Support ist nicht verfügbar',
        cancel: 'abbrechen',
        confirm: 'bestätigen',
        upgrade: 'upgrade',
        cancelConfirmation:
          'Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten?',
        resubscriptionConfirmation:
          'Sind Sie sicher, dass Sie Ihr Abonnement fortsetzen wollen?',
        subscriptionConfirmation:
          'Ihr Abonnement wird wiederaufgenommen und aktualisiert. Bitte bestätigen.',
        upgradesubscriptionConfirmation:
          'Sind Sie sicher, dass Sie Ihr Abonnement aktualisieren/ändern möchten?',
        subscriptionConsent:
          'Vielen Dank, dass Sie sich für ein Abonnement von hyperDENT Connect entschieden haben. Bitte geben Sie Ihre Zustimmung, um fortzufahren.',
        payment: {
          success:
            'Ihre Zahlung ist erfolgreich eingegangen. Sie werden in Kürze weitergeleitet.',
          failure:
            'Der Zahlungsvorgang war aufgrund einer manuellen Unterbrechung oder ungültiger Kartendaten nicht erfolgreich. Sie werden in Kürze weitergeleitet.',
        },
      },
      usageDetail: {
        title: 'Verbrauchsdetails',
        resourceName: 'Ressourcenname',
        used: 'verbraucht',
        limit: 'Limit',
        usagePercentage: 'Verbrauch in Prozent',
        errorMessage:
          'Beim Laden der Verbrauchsdaten ist ein Fehler aufgetreten.',
      },
      billing: {
        title: 'Zahlungen',
        custEmail: 'Kunden Email',
        custComp: 'Firmenname',
        fromPeriod: 'Zeitraum von',
        toPeriod: 'Zeitraum bis',
        amount: 'Anzahl',
        errorMessage: 'Beim Laden der Rechnung ist ein Fehler unterlaufen',
        manage: 'Zahlungen verwalten',
      },
      invoices: {
        title: 'Rechnungen',
        id: 'Rechnungs ID',
        itemDesc: 'Beschreibung',
        amountDue: 'Fälliger Betrag',
        download: 'Download',
        isPaid: 'Ist Bezahlt',
        paid: 'Bezahlt',
        due: 'fällig',
        errorMessage:
          'Ein Fehler ist beim Laden der Rechnungslisten unterlaufen',
        totalAmountDue: 'Gesamtbetrag',
        dateDue: 'Fälligkeitsdatum',
        upcoming: 'Folgrechnung',
        buttonOk: 'OK',
      },
    },
    addons: {
      title: 'Add-Ons',
      keepFiles: 'Datenspeicherungsdauer',
      days: 'Tage',
      month: 'Monate',
    },
  },
  errorBoundary: {
    errorMessage: 'Ups ! Etwas ist schiefgelaufen',
    errorFix: 'Technischer Fehler, wir arbeiten dran',
  },
  notifications: {
    label: 'Benachrichtigung',
    new: 'Neue Benachrichtigung',
    none: 'Sie haben keine neuen Benachrichtigungen',
    markAsUnread: 'Als ungelesen markieren',
    filter: {
      all: 'Alle',
      unread: 'Ungelesen',
    },
  },
  reports: {
    download: {
      error: {
        title: 'Fehler',
        message: 'Beim PDF Erstellen ist etwas schiefgelaufen',
      },
      success: {
        title: 'Erfolgreich',
        message: 'PDF erfolgreich erstellt',
      },
    },
    dateFormat: 'Berichtsformat ( Datum )',
  },
  licenseContent: {
    heading: 'License Content',
    placeholder: 'Search License Key',
    licenses: 'Licenses',
    products: 'Products',
    dongleId: 'Dongle Id',
    isNetwork: 'Is Network',
    isCommercial: 'Is Commercial',
    format: 'Format',
    invalid: 'Invalid Key Format',
    detail: 'License Details',
    columns: {
      ticket: 'Ticket',
      product: 'Product',
      licenseExpiry: 'License Expiration',
      maintenanceExpiry: 'Maintenance Expiry',
    },
    detailColumns: {
      code: 'Code',
      expiryDate: 'Expiration Date',
      featureMap: 'Feature Map',
      permissions: 'Permissions',
      information: 'Information',
      formalDongle: 'Former Dongle No.',
      endOfMaintenance: 'END of Maintenance',
      count: 'Count',
      productCode: 'Product Code',
    },
    tabActive: 'Active Licenses',
    tabOrdered: 'Ordered Licenses',
    tabDetails: 'Active Product Details',
  },
  dealer: {
    save: {
      title: 'Dealer Saved',
      message: 'Dealer data has been saved successfully',
    },
    update: {
      title: 'Update',
      message: 'Dealer data has been updated successfully',
    },
  },
  dealerManagement: {
    dealerHome: {
      heading: ' Welcome to the dealers dashboard ',
      navParagraph:
        'You can go to any of the following dashboard views by navigating from the sidebar or from the below mentioned links',
      navMenu: {
        dealerInformation: 'Dealer Information',
      },
    },
    heading: 'Dealers Management',
    invitation: 'Invite as User',
    reInvite: 'Reinvite User',
    newUser: 'New Dealer',
    dealerId: 'Dealer Id',
    name: 'Name',
    configurations: {
      parentDealerId: 'Parent Dealer Id',
      heading: 'Configurations',
      baseURL: 'Components Base URL',
      configType: 'Config Type',
      json: 'Configurations Json',
      file: 'Configurations File',
      url: 'Configurations Url',
    },
    contact: {
      dealerContact: 'Dealer Contact',
      supportContact: 'Support Contact',
      salesContact: 'Sales Contact',
      address1: 'Address 1',
      address2: 'Address 2',
      address3: 'Address 3',
      zipCode: 'Zip Code',
      city: 'City',
      district: 'District',
      state: 'State',
      country: 'Country',
      phone: 'Phone',
      email: 'Email',
      webUrl: 'Web Url',
    },
    configOptions: {
      none: 'None',
      fixedJson: 'Fixed Json',
      fixedFile: 'Fixed File',
      dynamicUrl: 'Dynamic Url',
    },
    buttonSave: 'Save',
    user: {
      heading: 'User Management',
      newUser: 'New User',
      dealer: 'Dealer',
      manager: 'Manager',
      columns: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        role: 'Role',
        actions: 'Actions',
      },
    },
    requiredDealerId: 'Dealer Id is required',
    requiredName: 'Dealer Name is required',
    requiredConfigType: 'Configuration Type is required',
    invalidJson: 'Invalid JSON',
    requiredParentEmail: 'Parent Dealer Id or Email is required',
    emailNotValid: 'Email is not valid',
  },
  hdUsageInfo: {
    usageInfo: 'hyperDENT Usage Info',
    runningMachines: 'Running Machines',
    concurrentUsers: 'Concurrent Users',
    lastUpdateReceivedAt: 'Last Update Received At',
    licenseKey: 'License Key',
    allowedUsers: 'Allowed Network Users',
    mulipleLogins: 'Multiple Logins',
    firstPing: 'First Ping Received At',
  },
  unknownError: 'An unknown error occured',
  dealerConfig: {
    test: 'Dealer configuration test result',
    invalidTicketFormat:
      'Invalid ticket format. Expected format: {{HD_TICKET_FORMAT_STR}}',
    response: 'Response will be shown here',
    wrapResponse: 'Wrap Response Text',
    initialCall: 'Initial Call',
  },
};
