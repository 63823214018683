import React from 'react';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import { DealerConfigResult } from 'pages/Shared/DealerConfigResult';

export const DealerConfigResultManagerView: React.FC = () => {
  return (
    <ManagerLayout>
      <DealerConfigResult />
    </ManagerLayout>
  );
};
