import { createSlice } from '@reduxjs/toolkit';
import { CONFIGURATION_TYPE } from 'constants/constants';
import { IApiState } from 'interfaces/api';
import { IDealerItem, IDealerObject } from 'pages/Shared/Dealer/Models/dealers';

export interface IDealerDetailState extends IApiState {
  data: IDealerObject;
}

const initialContactData = {
  id: 0,
  zipCode: '',
  city: '',
  state: '',
  country: '',
  phone: '',
  email: '',
  webUrl: '',
  address1: '',
  address2: '',
  address3: '',
  district: '',
};

const dealerFiles = {
  logo: '',
  configFile: '',
};

export const dealerInitialState: IDealerDetailState = {
  data: {
    id: 0,
    dealerId: '',
    name: '',
    parentDealerId: '',
    dealerContact: initialContactData,
    salesContact: initialContactData,
    supportContact: initialContactData,
    logo: '',
    files: dealerFiles,
    configurationType: CONFIGURATION_TYPE.NONE,
    fixedConfigJson: '',
    fixedConfigFile: [],
    dynamicConfigUrlPath: '',
    componentBaseURL: '',
    userId: 0,
    email: '',
  },
};

export const dealerItemInitialState: IDealerItem = {
  id: 0,
  dealerId: '',
  name: '',
  email: '',
};

export const dealerItemSlice = createSlice({
  name: 'dealerItem',
  initialState: dealerItemInitialState,
  reducers: {
    setDealerItem: (state, action) => {
      state.id = action.payload.id;
      state.dealerId = action.payload.dealerId;
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    setDealerEmail: (state, action) => {
      state.email = action.payload;
    },
    reset() {
      return { ...dealerItemInitialState };
    },
  },
});
