import React, { useEffect, useState } from 'react';
import { DealerListViewContainer } from 'pages/Shared/Dealer/DealerListView/DealersListViewContainer';
import {
  useGerBranchDealerListQuery,
  useGetDealerByUserIdQuery,
} from 'thunks/RTKQuery/dealer';
import { Spinner2 } from 'components';
import { IDealerItem } from 'pages/Shared/Dealer/Models/dealers';
import { useAppSelector } from 'store/hooks';

interface IRootDealerListViewContainer {
  setErrorString: (err: string) => void;
  setSelectedDealer: (dealer: IDealerItem) => void;
  selectedDealer?: IDealerItem;
  dealerListData: IDealerItem[];
  setDealerListData: (data: IDealerItem[]) => void;
  setRootDealer: (data: IDealerItem) => void;
}
// create DealerListView container for dealer management
export const RootDealerListViewContainer: React.FC<IRootDealerListViewContainer> = ({
  setErrorString,
  setSelectedDealer,
  selectedDealer,
  dealerListData,
  setDealerListData,
  setRootDealer,
}: IRootDealerListViewContainer) => {
  const { data } = useAppSelector((state) => state.auth);
  const user = data?.user;
  const [selectedId, setSelectedId] = useState<number>(0);
  const {
    data: rootDealer,
    isLoading: isRootDealerLoading,
    error: rootDealerError,
  } = useGetDealerByUserIdQuery(user.id, user?.id === 0 ? { skip: true } : {});

  useEffect(() => {
    if (rootDealer?.id) {
      setRootDealer(rootDealer);
      setSelectedId(rootDealer.id);
    }
  }, [rootDealer]);

  // load dealer list data
  const {
    data: dealerData = [],
    error: dealerListError,
    isLoading: dealerListLoading,
    // refetch: refetchDealerList,
  } = useGerBranchDealerListQuery(
    selectedId,
    selectedId === 0 ? { skip: true } : {}
  );

  useEffect(() => {
    if (!dealerData) return;
    setDealerListData(dealerData);
  }, [dealerData]);

  useEffect(() => {
    if (dealerListError && 'message' in dealerListError) {
      setErrorString(`Error :${dealerListError.message}`);
    }
    if (rootDealerError && 'message' in rootDealerError) {
      setErrorString(`Error :${rootDealerError.message}`);
    }
  }, [dealerListError, rootDealerError]);

  return (
    <>
      <Spinner2 show={dealerListLoading || isRootDealerLoading} />
      <DealerListViewContainer
        setSelectedDealer={setSelectedDealer}
        dealerData={dealerListData}
        selectedDealer={selectedDealer}
      />
    </>
  );
};

RootDealerListViewContainer.defaultProps = {
  selectedDealer: undefined,
};
