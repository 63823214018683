import React from 'react';
import { DealerListView } from './DealersListView';
import { IDealerItem } from '../Models/dealers';

interface IDealerListViewContainer {
  setSelectedDealer: (dealer: IDealerItem) => void;
  selectedDealer?: IDealerItem;
  dealerData: IDealerItem[];
}

export const DealerListViewContainer = ({
  setSelectedDealer,
  selectedDealer,
  dealerData,
}: IDealerListViewContainer) => {
  const handleOnSelect = async (row: any) => {
    const item = row as IDealerItem;
    setSelectedDealer(item);
  };

  return (
    <>
      <DealerListView
        dealerData={dealerData}
        selectedDealer={selectedDealer}
        handleOnSelect={handleOnSelect}
      />
    </>
  );
};

DealerListViewContainer.defaultProps = {
  selectedDealer: undefined,
};
